import 'vite/modulepreload-polyfill'
import { initDisclosureWidgets } from '@/lib/a11y'
import revive from '@/lib/revive.js'
import init from '@/lib/init'
import { initTabKeyListener } from '@/lib/accessibility'
import { initBluecore } from '@/lib/bluecore'
import scrollAnimation from '@/lib/scroll-animation'
import sal from 'sal.js'

const islands = import.meta.glob('@/islands/**.js')
const summaries = document.querySelectorAll('[id^="Details-"] summary')

revive(islands)
initDisclosureWidgets(summaries)

if (window.decodeURI(window.location.pathname) === '/account/addresses') {
  import('@/lib/shopify_common')
  import('@/lib/customer-address')
}

document.addEventListener('DOMContentLoaded', () => {
  init().mount()
  initTabKeyListener()
  scrollAnimation()
  initBluecore()
  // Do not remove "window.scrollAnimations" because it's currently used in multiple places
  window.scrollAnimations = sal({
    threshold: 0.1,
  })
})